import type {PageProps} from 'gatsby'
import {graphql} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'

import {Layout} from '@/components/layout'
import type {GetPageQuery} from '@/graphql-types'

const MDXRendererLayout = (props: {data: GetPageQuery} & PageProps) => {
    return (
        <Layout
            {...props}
            pageContext={{
                frontmatter: props.data.mdx.frontmatter
            }}
        >
            <MDXRenderer headings={props.data.mdx.headings}>{props.data.mdx.body}</MDXRenderer>
        </Layout>
    )
}

export default MDXRendererLayout

export const query = graphql`
    query getPage($id: String!) {
        mdx(id: {eq: $id}) {
            frontmatter {
                title
                tabs
                intro
                status
                layoutMode
                githubUrl
                storybookUrl
                figmaUrl
                editPageUrl
            }
            body
            headings {
                depth
                value
            }
        }
    }
`
